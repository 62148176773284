import React from 'react';

import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import { Flow, Stack } from 'src/system2';
import * as css from './drawer-logo-chain-v2.css';

export default function Drawer({ heading, callouts, customData }) {
  const logos = callouts.filter((callout) => callout.__typename == 'DatoCmsLogoBlock');
  customData = JSON.parse(customData);

  return (
    <css.Section {...customData?.flags}>
      <Stack sx={{ pt: 40, pb: { _: 20, m: 32 }, gap: 24 }}>
        {heading && (
          <p css={[css.heading]}>
            {heading}
          </p>
        )}
          <Flow
            as="ul"
            role="list"
            sx={{
              gapX: { _: 40, s: 40, m: 60, l: 80 },
              gapY: { _: 16, m: 32 },
              mx: 'auto',
              jc: 'center',
              px: { _: 20, m: 40, l: 80 },
            }}
            style={{ filter: 'url(#logolist)' }}
          >
            {logos?.map((logo, i) => {
              return (
                <li key={i} role="listitem" css={[css.logoBlock]}>
                  <Link to={logo.url}>
                    <div
                      className="logo-hold"
                      style={{
                        width: `calc(${logo.media.width / logo.media.height} * var(--logo-height))`,
                        height: `var(--logo-height)`,
                      }}
                    >
                      <FluidImg
                        constrain
                        width="100%"
                        data={logo.media}
                        style={{
                          opacity: 0.8,
                        }}
                      />
                    </div>
                  </Link>
                </li>
              );
            })}
          </Flow>
      </Stack>
    </css.Section>
  );
}
